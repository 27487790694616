<template>
<div>
    <div>
        <p class="text-center"><a :href="url">戻る</a></p>
    </div>
    <footer>
        <p class="text-center">Copyright© nextjapan All Rights Reserved.</p>
    </footer>
</div>
</template>

<script>
import { SITE_URL } from '../packs/config'

export default {
    data: function() {
        return { 
            url: SITE_URL
        }
    }
}
</script>