<template>
    <header>
        <a href="https://nextjapan.tokyo/" target="__blank" rel="noopener">
            <img src="~logo" alt="ネクストジャパン">
        </a>
    </header>
</template>

<script>
import 'logo';
export default {}
</script>