import Vue from 'vue';
import VueRouter from 'vue-router';
import Steps from '../components/Steps';
import Goals from '../components/Goals';
import Thankyou from '../components/Thankyou';

import { getValueByKey } from './store'

Vue.use(VueRouter);

const routes = [
    {path: '/', component: Steps },
    {path: '/goals', component: Goals, beforeEnter: (to, from, next) => {
        if (from.path !== '/' || getValueByKey('history') === null ) {
            next({ path: '/' })
        } else {
            next()
        }
    }},
    {path: '/thankyou', component: Thankyou, beforeEnter: (to, from, next) => {
        if (from.path !== '/goals') {
            next({ path: '/' })
        } else {
            next()
        }
    }}
];

const router = new VueRouter({ 
    mode: 'history', 
    routes 
})
  
export default router;