// axiosの利用時に自動でCSRFトークンを設定する
import Axios from 'axios';

Axios.interceptors.request.use((config) => {
  if(['post', 'put', 'patch', 'delete'].includes(config.method)) {
    config.headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').content;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});