<template>
  <main>
    <div class="container">
      <div class="container_head">
        <h2>今すぐ！助成金診断　結果が出ました</h2>
      </div>
      <div class="singleColumn regist">
        <div v-if="!this.message">
          <div class="block_center">
            <b><p class="text-center"><font size="+2">最大受給金額</font></p></b>
            <p class="text-center">
              <b><font size="+2">御社は最大</font></b>
              <b><font size="+3" COLOR="#cc0033">{{ parseInt(sum) * 10000 | numberFormat }}円</font></b>
              <b><font size="+2">受給が可能です</font></b>
            </p>
          </div>
          <br>
          <table>
            <tbody>
              <table>
                <tr v-for="l in subsidies" :key="l.id">
                  <td class="subttl font-weight-bold" style="width: 40%;">{{ l.title }}</td>
                  <td style="width: 60%;">
                    {{ l.summary }}<br>
                    最大支給額: {{ parseInt(l.amount) * 10000 | numberFormat }}円
                  </td>
                </tr>
              </table>
            </tbody>
          </table>
          <br>
        </div>
        <div v-else>
          <div class="block_center">
            <p class="centering-message" style="white-space: pre-wrap;"><b><font size="+1">{{ message }}</font></b></p>
          </div>
        </div>
        <br>
        <div>
          <p class="centering-message mb-20">
          <b><font COLOR="#cc0033">診断結果を元に、そのまま受給相談も可能です！</font></b><br>
          メールアドレスをご入力の上、送信ください。初回相談は無料です。
          </p>
        </div>
        <table>
          <tbody>
            <tr>
              <th colspan="2" class="subttl" style="width: 30%"><label for="firmNm">メールアドレス</label><span class="required">必須</span></th>
              <td colspan="2" style="width: 70%">
                <input type="text"  maxlength="150" required id="firmNm" name="firmNm" v-model='input.email'>
              </td>
            </tr>
            <tr>
              <th colspan="2" class="subttl"><label for="firmNm">メールアドレス確認<span class="required">必須</span></label></th>
              <td colspan="2">
                <input type="text" maxlength="150" required id="firmNm" name="firmNm"  v-model='input.email_confirm'/>
              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <div align="center">個人情報保護方針について：
          <a href="http://i-assist2.sakura.ne.jp/nextstep2/ppolicy.html" target="_blank">個人情報保護方針を読む</a>
          <br>
          <input id="ppolicy_check" type="checkbox" name="個人情報保護方針に同意する" value="個人情報保護方針に同意する" required v-model="ppolicy_check">
          <label for="ppolicy_check">個人情報保護方針について同意する</label>
        </div>
        <div class="btn_area column">
          <input type="button" class="gray_btn" value="キャンセル" @click="cancel()">
          <input type="submit" class="submit_btn" value="送信する" @click="send()">
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import router from '../packs/router';
import { getValueByKey } from '../packs/store';

export default {
    created() {
      console.log('components created')

      // セッションストレージ内でグローバル変数として診断結果と回答履歴を持ちまわす
      if (getValueByKey('message')) {
          this.message = getValueByKey('message');
      }

      if (getValueByKey('history')) {
        console.log(getValueByKey('history'));
          this.history = getValueByKey('history');
      }
      if (this.message) {
        return; // 受給額の計算はせずにメッセージだけ表示
      }

      const history = { history: this.history };
      console.log('history', history)
      axios.get('/api/diagnostics/goal', { params: history })
        .then((res) => {
          this.sum = res.data.sum;
          this.subsidies = res.data.subsidies;
        })
        .catch((error) => {
          console.log(error)
        })

    },
    data() {
        return {
            message: '',
            history: [],
            sum: 0,
            subsidies: [],
            input: {
              email: '', 
              email_confirm: '',
            },
            ppolicy_check: false
        }
    },
    methods: {
      cancel: function() {
        router.push({ path: '/' })
      },

      send: function() {
        if (this.input.email === '' || this.input.email_confirm === '') {
          alert('メールアドレスを入力してください');
        }

        if (this.input.email !== this.input.email_confirm) {
          alert('メールアドレスが正しく入力されているかご確認下さい');
        }

        if (!this.ppolicy_check) {
          alert('個人情報保護方針について同意頂けない場合はご利用頂けません');
        }

        const params = { email: this.input.email, subsidies: this.subsidies, sum: this.sum, message: this.message }
        axios.post('/api/diagnostics/result', params)
          .then((res) => {
            console.log(res.data)
            router.push({ path: '/thankyou'})
          })
          .catch((error) => {
            console.log(error)
          })


      }
    },
    filters:{
      numberFormat:function(value){
        return value.toLocaleString()
      }
    }

}
</script>

<style scoped>
  .block_center {
    width: 80%;
    margin: 0 auto;
  }

  .centering-message{
    text-align: center;
  }

  .mb-20{
    margin-bottom: 20px;
  }

</style>
  