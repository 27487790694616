<template>
  <main>
    <div class="container">
      <div class="container_head">
        <h2>今すぐ！助成金診断　結果を送信しました</h2>
      </div>
      <div class="singleColumn regist">
        <br>
        <div>
          <p class="centering-message mb-20">
          <b><font COLOR="#cc0033">診断結果を入力されたメールアドレス宛に送信しました。</font></b><br>
          是非、NEXTJAPANの<a href='https://system.nextjapan.tokyo/#/user/login'>TicTac（チクタク）</a>より、助成金の申請をご検討ください。
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { removeValueByKey } from '../packs/store';

export default {
    created() {
      // 診断結果と回答履歴の初期化
        this.message = removeValueByKey('message');
        this.history = removeValueByKey('history');
    },
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>

<style scoped>
  .block_center {
    width: 80%;
    margin: 0 auto;
  }

  .centering-message{
    text-align: center;
  }

  .mb-20{
    margin-bottom: 20px;
  }

</style>
  