<template>
    <main>
      <div class="container">
        <vue-loading v-show="loading" type="spin" color="#333" :size="{ width: '50px', height: '50px' }" style="z-index: 999"></vue-loading>
        <div class="container_head">
          <h2>今すぐ！助成金診断　Step{{ question.id }}</h2>
        </div>
        <div class="singleColumn regist">
            <div>
              <p class="form_read_l">
                <font style="font-size : 150%;">
                  <p>{{ question.question }}</p>
                </font>
              </p>
            </div>
            <table class="mgb20" width="70%">
              <tbody>
                <tr>
                  <th class="text-center" align="center" style="width: 41%">回答{{ question.id }}</th>
                  <td style="width: 59%">
                    <ul v-for="l in answer" :key="l.id">
                      <li>
                        <input :id="l.id" type="radio" name="radio" v-model="pair.select_answer_id" :value="l.id" @click="updateNextQuestionId(l.next_question_id)">
                        <label :for="l.id">{{ l.answer }}</label>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="btn_area column">
                <input type="button" v-if="history.length !== 0" class="gray_btn"  @click="previous()" value="前画面へ">
                <input type="submit" id='nextbutton' class="submit_btn" @click="next()" value="次へ">
            </div>
        </div>
      </div>
    </main>

</template>

<script>
import axios from 'axios';
import router from '../packs/router';
import { getValueByKey, removeValueByKey, setKeyValue } from '../packs/store';
import { VueLoading } from 'vue-loading-template';

export default {
  created: function() {
    this.initialize();

    const params = { id: this.pair.next_question_id };
    this.reload(params);
},

  methods: {
    initialize: function() {
      if (getValueByKey('history')) {
        removeValueByKey('history')
      }
      if (getValueByKey('message')) {
        removeValueByKey('message')
      }
    },

    reload: async function(params) { 
      this.loading = true;
      await axios.get('/api/diagnostics/get_question', { params: params })
        .then((res) => {
          this.question = res.data;
      }).catch((err) => {
        console.log(err);
      })

      await axios.get('/api/diagnostics/get_answers', { params: params})
        .then((res) => {
          this.answer = res.data;
      }).catch((err) => {
        console.log(err)
      })
      this.loading = false;

    },
    updateNextQuestionId: function(next_question_id) {
        this.pair.next_question_id = next_question_id;
    },

    redirectIfExit: function() {
      // 受給資格がないため離脱
      if (this.pair.next_question_id === -1) {
        this.answer.forEach(ans => {
          if (ans.next_question_id === -1) {
            this.message = ans.message;
          }
        })
      }

      this.history.push(JSON.parse(JSON.stringify(this.pair)));
      setKeyValue('history', this.history)
      console.log(this.message.split('。'))
      console.log(this.message.split('。').join('<br>'))

      this.message = this.message.split('。').join('\n');
      setKeyValue('message', this.message)
      router.push({ path: '/goals'})
      return;
    },

    redirectIfFinish: function() {
      // すべての質問に回答したため離脱
      setKeyValue('history', JSON.stringify(this.history))
      router.push({ path: '/goals'})
      return;
    },

    next: function() {
      if (this.pair.select_answer_id === null) {
        alert('選択してください。');
        return;
      }
      if (this.pair.next_question_id == 0) {
        this.pair.next_question_id = this.question.id + 1;
      }

      this.history.push(JSON.parse(JSON.stringify(this.pair)));

      if (this.pair.next_question_id === -1) {
        this.redirectIfExit();
        return;
      }

      if (this.pair.next_question_id === 99) {
        this.redirectIfFinish();
        return;
      }

      const params = { id: this.pair.next_question_id };
      this.reload(params);
    
      this.pair.select_answer_id = ""; // 回答の選択の初期化

    },

    previous: function() {
      console.log('preview');
      this.history.pop();
      let history_item = this.history[this.history.length-1]; 
      if (this.history.length === 0) {
        this.reload({id: 1}); // 1番目の質問に戻った場合 
        return;
      }
      const params = { id: history_item.next_question_id };
      this.reload(params);
    },
    
    cancel: function() {
      console.log('cancel')
    },

  },

  data: function () {
    return {
      loading: false,
      pair: {select_answer_id: null, next_question_id: 1},
      question: [],
      answer: [],
      history: [],
      message: '',
    }
  },

  components: {
    VueLoading,
  }
}
</script>

<style>
  .back_btn {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    margin-top: 50px;
  }
</style>