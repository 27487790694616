export function getValueByKey(key) {
    return window.sessionStorage.getItem(key)
}

export function removeValueByKey(key) {
    window.sessionStorage.removeItem(key);
    if (window.sessionStorage.getItem(key) === null) {
        return true;
    }
    return false;
}

export function setKeyValue(key, value) {
    window.sessionStorage.setItem(key, value)
}