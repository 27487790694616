<template>
  <div>
    <Header/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Steps from './components/Steps';
import Goals from './components/Goals';
import Header from './components/Header';
import Footer from './components/Footer';
import Thankyou from './components/Thankyou';

export default {
  components: { Header, Footer, Steps, Goals, Thankyou }
}
</script>
